async function loadBootstrap() {
	await import("./Bootstrap");
	document.body.classList.add("bs-loaded");
}

void loadBootstrap();

if (document.getElementById("edit-start-page")) {
	void import("Start/Start");
}

if (document.getElementById("edit-rss-feed")) {
	void import("Rss/Rss");
}

if (document.getElementById("tools")) {
	void import("Tools/Tools");
}

if (document.querySelector("textarea.monaco")) {
	void import("Components/MonacoEditorStandalone");
}

export { };

